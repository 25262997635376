import { DBCONFIG } from "../Constants";

export const getData = async (query) => {
    const response = await fetch('https://sql-integration-api.deploy.ecoquimic.com/data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            dbConfig: DBCONFIG,
            query
        }),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const result = await response.json();

    return result;
}
import { Button, Select, Spin } from 'antd';
import React from 'react';
import DatePicker from './DatePicker';
import { UndoOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const TableHeader = ({ loading, dates, changeData, refresh, type, setType }) => {
    return (
        <div>
            <Spin fullscreen={loading} spinning={loading}>
                <div style={{ marginTop: 20, marginLeft: 10 }}>
                    <RangePicker value={dates} onChange={changeData} type={type} showHour showTime={type === "hour"} />
                    <Button style={{ marginLeft: 20 }} onClick={refresh} type='primary'><UndoOutlined />Refrescar</Button>
                    <Select style={{ marginLeft: 20 }} value={type} onChange={setType} options={[{ value: "hour", label: "Hora" }, { value: "day", label: "Día" }, { value: "week", label: "Semana" }, { value: "month", label: "Mes" }, { value: "year", label: "Año" }]}></Select>
                </div>
            </Spin>
        </div>
    );
};

export default TableHeader;
